const version = "4.0517.1330";

import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
//importamos amplify para cognito
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);
import VueResource from "vue-resource";
//importamos librerias
import VueMask from "v-mask";
Vue.use(VueMask);

import VueSession from "vue-session";
import VuePageTransition from "vue-page-transition";
import VueHtmlToPaper from "vue-html-to-paper";
import { DateTime, Interval } from "luxon";
//configuramos libreriras
Vue.use(VueHtmlToPaper, {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: ["./styles/print.css"],
});
Vue.use(VueSession, {
  persist: true,
});
Vue.use(VueResource);
Vue.use(VuePageTransition);

// const api = "http://localhost/crmcuu/crmcuu3-website-api/";
const api = "https://api.cruzrojachihuahua.org/web/";
Vue.prototype.$apiAdmin = "https://api.cruzrojachihuahua.org/administracion/";

Vue.http.options.root = api;
Vue.prototype.$api = api;

Vue.prototype.$files = "http://archivos.cruzrojachihuahua.org/";
Vue.prototype.$version = version;

Vue.http.options.emulateJSON = true;
Vue.config.productionTip = false;
//funciones y configuraciones custom que deben ser globales
Vue.prototype.$primary = "#121417";
Vue.prototype.$secondary = "#B40A1B";
Vue.prototype.$accent = "#BC0022";
Vue.prototype.$error = "#F85C50";
Vue.prototype.$background = "#fefffe";

Vue.prototype.$headers = {
  headers: {
    Token: null,
    "Cache-Control": "no-cache",
  },
};

const $llenarCampo = "Este campo es obligatorio";
Vue.prototype.$llenarCampo = $llenarCampo;
Vue.prototype.$lleneCampos = "Llena los campos indicados";
Vue.prototype.$noInternet = "Verifica tu conexión a internet";
Vue.prototype.$noData = "No hay datos disponibles";

Vue.prototype.$nF = (n) => {
  return Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(n);
};

Vue.prototype.$Datetime = DateTime;
Vue.prototype.$Interval = Interval;

Vue.prototype.$hasError = (a) => {
  return a ? null : $llenarCampo;
};

Vue.prototype.$validate = (arr) => {
  return Object.values(arr).indexOf($llenarCampo) <= -1 ? true : false;
};

Vue.prototype.$EventBus = new Vue();

import device from "vue-device-detector-js";
Vue.use(device);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
