<template>
  <v-app style="flex: 1 1 auto!important;">
    <v-main style="background:transparent">
      <div :class="appClasses">
        <Header />
        <main>
          <vue-page-transition name="fade">
            <router-view
              @classes="_handleClasses"
              @msg="_notify"
              @httpError="_resFail"
            />
          </vue-page-transition>
        </main>
        <Footer />
      </div>
    </v-main>
    <v-snackbar
      v-model="snackbar.show"
      top
      :timeout="4500"
      absolute
      right
      shaped
    >
      <v-btn
        text
        @click="snackbar.show = false"
        icon
        class="dark-icon"
      >
        <v-icon v-text="'close'" />
      </v-btn>
      {{ snackbar.text }}
    </v-snackbar>
  </v-app>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: { Header, Footer },
  data() {
    return {
      // appClasses: "technical-drawing",
      appClasses: "",
      showClasses: "",
      snackbar: {
        show: false,
        text: "",
      },
    };
  },
  methods: {
    _handleClasses(clss) {
      this.appClasses = clss;
    },
    _notify(a) {
      this.snackbar.text = a;
      this.snackbar.show = true;
    },
    _resFail(a) {
      if (a.status === 401) {
        const ruta = this.$route.fullPath.includes("persona")
          ? "/personal"
          : "/empresas/login";
        this.$router.push(ruta);
      } else if (a.status === 400) {
        this._notify(a.body.error);
      } else if (a.body.error) {
        this._notify(a.body.error);
      } else {
        this._notify(this.$noInternet);
      }
    },
  },
};
</script>
<style lang="scss">
@import "styles/app";
</style>
