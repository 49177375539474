import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  //sitio web
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/aviso-de-privacidad",
    name: "AvisoPrivacidad",
    component: () => import("@/views/AvisoPrivacidad.vue"),
  },
  {
    path: "/citas",
    name: "CitasForm",
    component: () => import("@/views/citas/CitasForm.vue"),
  },
  {
    path: "/citas",
    name: "CitasForm",
    component: () => import("@/views/citas/CitasForm.vue"),
  },
  {
    path: "/confirmacion-de-cita/:idCit?",
    name: "CitasConfirmacion",
    component: () => import("@/views/citas/CitasConfirmacion.vue"),
  },
  {
    path: "/capacitacion",
    name: "CursosMain",
    component: () => import("@/views/cursos/CursosMain.vue"),
  },
  {
    path: "/servicios",
    name: "Servicios",
    component: () => import("@/views/Servicios.vue"),
  },
  {
    path: "/sobre-nosotros",
    name: "SobreNosotros",
    component: () => import("@/views/SobreNosotros.vue"),
  },
  {
    path: "/unidades-medicas",
    name: "UnidadesMedicas",
    component: () => import("@/views/UnidadesMedicas.vue"),
  },
  //microportal para el manejo del personal
  {
    path: "/personal",
    name: "PersonalMain",
    component: () => import("@/views/personal/PersonalMain.vue"),
    children: [
      {
        path: "",
        name: "PersonalLogin",
        component: () => import("@/components/personal/PersonalLogin.vue"),
      },
      {
        path: "perfil",
        name: "PersonalPerfil",
        component: () => import("@/components/personal/PersonalPerfil.vue"),
      },
      {
        path: "documentacion",
        name: "PersonalDocumentacion",
        component: () =>
          import("@/components/personal/PersonalDocumentacion.vue"),
      },
      {
        path: "vacaciones",
        name: "PersonalVacaciones",
        component: () => import("@/components/personal/PersonalVacaciones.vue"),
      },
    ],
  },
  //microportal para los que los beneficiarios capturen a su familia
  {
    path: "/beneficiario/:codigo?",
    name: "BeneficiarioMain",
    component: () => import("@/views/beneficiario/BeneficiarioMain.vue"),
  },
  {
    path: "/gracias/:tipo?",
    name: "Gracias",
    component: () => import("@/views/Gracias.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/views/404.vue"),
  },
  //atencion a empresas
  {
    path: "/empresas",
    name: "EmpresasMain",
    component: () => import("@/views/empresas/EmpresasMain.vue"),
    children: [
      {
        path: "",
        name: "EmpresasHome",
        component: () => import("@/views/empresas/EmpresasHome.vue"),
      },
      //sesion
      {
        path: "registro",
        name: "EmpresasRegistro",
        component: () => import("@/views/empresas/EmpresasRegistro.vue"),
      },
      {
        path: "login/:email?",
        name: "login",
        component: () => import("@/views/empresas/EmpresasLogin.vue"),
      },
      //ambulancias
      {
        path: "/empresas/ambulancias",
        name: "AmbulanciasListado",
        component: () => import("@/views/ambulancias/AmbulanciasListado.vue"),
      },
      {
        path: "/empresas/ambulancia",
        name: "AmbulanciasMain",
        component: () => import("@/views/ambulancias/AmbulanciasMain.vue"),
      },
      {
        path: "/empresas/ambulancia/serviciosespeciales",
        name: "AmbulanciasServiciosespeciales",
        component: () =>
          import("@/views/ambulancias/AmbulanciasServiciosEspeciales.vue"),
      },
      {
        path: "/empresas/ambulancia/trasladoslocales",
        name: "AmbulanciasTrasladoslocales",
        component: () =>
          import("@/views/ambulancias/AmbulanciasTrasladosLocales.vue"),
      },
      {
        path: "/empresas/ambulancia/trasladosforaneos",
        name: "AmbulanciasTrasladosforaneos",
        component: () =>
          import("@/views/ambulancias/AmbulanciasTrasladosForaneos.vue"),
      },
      {
        path: "/empresas/ambulancia/detalle/:idEva",
        name: "AmbulanciasDetalle",
        component: () => import("@/views/ambulancias/AmbulanciasDetalle.vue"),
      },
      //proveedores
      {
        path: "/empresas/ordenesdecompra",
        name: "OrdenesCompraListado",
        component: () =>
          import("@/views/ordenescompra/OrdenesCompraListado.vue"),
      },
      {
        path: "/empresas/ordendecompra/:id",
        name: "OrdenesCompraDetalle",
        component: () =>
          import("@/views/ordenescompra/OrdenesCompraDetalle.vue"),
      },
      //beneficiarios
      {
        path: "/empresas/beneficiario",
        name: "BeneficiariosForm",
        component: () => import("@/views/beneficiarios/BeneficiariosForm.vue"),
      },
      {
        path: "/empresas/beneficiarios",
        name: "BeneficiariosListado",
        component: () =>
          import("@/views/beneficiarios/BeneficiariosListado.vue"),
      },
      //facturacion
      {
        path: "factura",
        name: "FacturacionForm",
        component: () => import("@/views/facturacion/FacturacionForm.vue"),
      },
      {
        path: "facturas",
        name: "FacturacionListado",
        component: () => import("@/views/facturacion/FacturacionListado.vue"),
      },
      //informacion del proveedor
      {
        path: "proveedor",
        name: "ProveedorForm",
        component: () => import("@/views/proveedor/ProveedorForm.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  // mode: 'history',
  // root: '/',
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0, behavior: "smooth" };
  },
});

export default router;
