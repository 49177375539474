<template>
  <div>
    <header class="header header--technical __js_fixed-header">
      <div class="header__inner">
        <div class="header__part header__part--start">
          <a class="header__logo logo">
            <span class="logo__image">
              <svg
                width="52"
                height="52"
                aria-label="logo"
                viewBox="0 0 61 61"
              >

                <polygon
                  class="cls-1"
                  style="fill:#ed1b2e;stroke:#ed1b2e;stroke-miterlimit:10;"
                  points="60.5 20.5 40.5 20.5 40.5 0.5 20.5 0.5 20.5 20.5 0.5 20.5 0.5 40.5 20.5 40.5 20.5 60.5 40.5 60.5 40.5 40.5 60.5 40.5 60.5 20.5"
                />
              </svg>
              <image xlink:href="~@/assets/logo.svg" />
            </span>
            <span
              class="logo__text"
              style="font-family: Helvetica !important; font-weight: bold; min-width: 75%;letter-spacing: .018rem;"
            >Cruz Roja Mexicana<br><small><strong>Delegación Chihuahua</strong></small></span>
          </a>
        </div>
        <div class="header__part header__part--center">
          <nav class="header__nav navigation navigation--technical">
            <ul class="navigation__list">
              <li
                class="navigation__item"
                v-for="(link,i) in links"
                :key="i"
              >
                <router-link
                  class="navigation__link"
                  :to="link.to"
                  v-text="link.text"
                />
                <div
                  class="navigation__dropdown navigation__dropdown--columns"
                  v-if="link.hasSubmenu"
                >
                  <div class="navigation__dropdown-wrapper">
                    <div class="navigation__dropdown-column">
                      <div class="navigation__dropdown-column-title">{{link.text}}</div>
                      <ul class="navigation__dropdown-list">
                        <li
                          class="navigation__dropdown-item"
                          v-for="(sublink,si) in link.links"
                          :key="si"
                        >
                          <router-link
                            v-if="sublink.visible"
                            :to="sublink.to"
                            v-text="sublink.text"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        </div>
        <div class="header__part header__part--end">
          <button
            class="header__menu-toggle menu-toggle"
            type="button"
            @click="openMenu = !openMenu"
          >
            <span class="visually-hidden">Menu</span>
          </button>
        </div>
      </div>
    </header>
    <section
      class="menu"
      :class="openMenu ? 'menu--opened' : ''"
    >
      <div class="menu__inner">
        <button
          class="menu__close"
          type="button"
          @click="openMenu = !openMenu"
        >
          <svg
            width="24"
            height="24"
            id="close"
            viewBox="0 0 64 64"
          >
            <path d="M35.4 32l19.9-19.9c1-1 1-2.4 0-3.4s-2.4-1-3.4 0L32 28.6 12 8.8c-.9-1-2.4-1-3.3 0s-1 2.4 0 3.4L28.6 32 8.7 51.9c-1 1-1 2.4 0 3.4.5.4 1 .7 1.7.7s1.2-.2 1.7-.7l20-19.9 20 19.8c.5.4 1.2.7 1.7.7s1.2-.2 1.7-.7c1-1 1-2.4 0-3.4L35.4 32z"></path>
          </svg>
          <span class="visually-hidden">Close menu</span>
        </button>
        <div class="menu__wrapper">
          <ul class="menu__list">
            <!-- <li class="menu__item"> <a class="menu__link">Home</a> </li> -->
            <div
              v-for="(link,i) in links"
              :key="i"
              class="menu__item"
            >
              <li v-if="!link.hasSubmenu">
                <router-link
                  class="menu__link"
                  v-text="link.text"
                  :to="link.to"
                />
              </li>
              <li
                class="menu__item"
                v-for="(sublink,sl) in link.links"
                :key="sl"
              >
                <small v-if="sl === 0">{{link.text}}<br></small>
                <div v-if="sublink.visible">
                  <router-link
                    class="menu__link"
                    v-text="sublink.text"
                    :to="sublink.to"
                  />
                </div>
              </li>
            </div>
          </ul>
        </div>
        <div class="menu__bottom">
          <div class="menu__copy">
            © 2022 <span>Cruz Roja Mexicana I.A.P. Delegación Chihuahua.</span> Todos los derechos.
            <br>
            <desarrollado />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Desarrollado from "@/components/Desarrollado.vue";
// import { consoleInfo } from "vuetify/lib/util/console";
export default {
  name: "Header",
  setup() {},
  components: { Desarrollado },
  data() {
    return {
      openMenu: false,
      links: [
        {
          text: "Inicio",
          to: "/",
          hasSubmenu: false,
        },
        {
          text: "Unidades Médicas",
          to: "/unidades-medicas",
          hasSubmenu: false,
        },
        { text: "Servicios", to: "/servicios", hasSubmenu: false },
        { text: "Citas médicas", to: "/citas", hasSubmenu: false },
        { text: "Capacitación", to: "/capacitacion", hasSubmenu: false },
        {
          text: "Atención a empresas",
          to: "/empresas",
          hasSubmenu: true,
          links: [
            {
              to: "/empresas",
              text: "Ingresa al portal",
              visible: true,
            },
            {
              to: "/empresas/registro",
              text: "Registrate como empresa",
              visible: true,
            },
            {
              to: "/empresas/factura",
              text: "Facturación",
              visible: true,
            },
            {
              to: "/empresas/ambulancia",
              text: "Solicitud de ambulancias",
              visible: true,
            },
            // {
            //   to: "/capacitacion",
            //   text: "Capacitación & cursos",
            //   visible: true,
            // },
            {
              to: "/empresas/login",
              text: "Salir",
              visible: false,
            },
          ],
        },
        {
          text: "Más",
          to: "#",
          hasSubmenu: true,
          links: [
            // { to: "/voluntariado", text: "Voluntariado" },
            // { to: "/paramedicos", text: "Escuela de paramédicos" },
            {
              to: "/empresas/ambulancia",
              text: "Solicitud de ambulancias",
              visible: true,
            },
            {
              to: "/empresas/factura",
              text: "Facturación",
              visible: true,
            },
            {
              to: "/beneficiario",
              text: "Beneficiario",
              visible: true,
            },
            // { to: "/personal", text: "Personal Cruz Roja", visible: true, },
            {
              to: "/aviso-de-privacidad",
              text: "Aviso de Privacidad",
              visible: true,
            },
          ],
        },
      ],
    };
  },
  methods: {
    _handleMenuEmpresas() {
      const i = 5;
      try {
        if (this.$session.exists() && this.$session.get("tipo") === "donador") {
          this.links[i].links[0].visible = true; //text: "Ingresa al portal", visible: true,
          this.links[i].links[0].text = "Portal de empresas"; //text: "Ingresa al portal", visible: true,
          this.links[i].links[1].visible = false; //text: "Registrate como empresa", visible: true,
          this.links[i].links[2].visible = true; //text: "Facturación", visible: true,
          this.links[i].links[3].visible = true; //text: "Solicitud de ambulancias", visible: true,
          this.links[i].links[4].visible = true; //text: "Capacitacion & cursos, visible:true
          this.links[i].links[5].visible = true; //text: "Salir", visible: false,
          // this.links[i].links[4].visible = true; //text: "Salir", visible: false,
        } else {
          this.links[i].links[0].visible = true; //text: "Ingresa al portal", visible: true,
          this.links[i].links[0].text = "Ingresa al portal"; //text: "Ingresa al portal", visible: true,
          this.links[i].links[1].visible = true; //text: "Registrate como empresa", visible: true,
          this.links[i].links[2].visible = true; //text: "Facturación", visible: true,
          this.links[i].links[3].visible = true; //text: "Solicitud de ambulancias", visible: true,
          this.links[i].links[4].visible = true; //text: "Capacitacion & cursos, visible:true
          this.links[i].links[5].visible = false; //text: "Salir", visible: false,
          // this.links[i].links[4].visible = false; //text: "Salir", visible: false,
        }
      } catch (err) {}
    },
  },
  created() {
    this._handleMenuEmpresas();
  },
  mounted() {
    this.$EventBus.$on("menu-empresas-change", (data) => {
      this._handleMenuEmpresas();
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("menu-empresas-change");
  },
  watch: {
    $route() {
      this._handleMenuEmpresas();
    },
  },
};
</script>