<template>
  <div>
    Desarrollado por <span>
      <a
        href="https://www.nebulajuno.com"
        target="_blank"
        style="color:#83111b"
        v-text="'Nebula Juno'"
      />
      <br>
      <small>{{$version}}</small>
    </span>
  </div>
</template>
<script>
export default {
  name: "Desarrollado",
};
</script>