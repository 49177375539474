import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#1A2026',
        secondary: '#A62639',
        accent: '#A62639',
        error: '#EE3D48',
        background: '#ffffff',
        info: '#004b79',
        success: '#4CAF50',
        warning: '#ecb731'
        // VUE_APP_COLOR_PRIMARY = "#1A2026"
        // VUE_APP_COLOR_SECONDARY = "#A62639"
        // VUE_APP_COLOR_ACCENT = "#A62639"
        // VUE_APP_COLOR_ERROR = "#F85C50"
        // VUE_APP_COLOR_BACKGROUND = "#fff"
        // VUE_GOOGLE_MAPS_API_KEY="AIzaSyBCPlC9Ab8ROlXD5GZLrJ-7pJ4iUZ8XA10"
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'md',
  },
});
