<template>
  <footer class="footer footer--technical">
    <div class="footer__top">
      <div class="container">
        <p>Si necesitas ayuda personalizada no dudes en llamarnos al </p>
        <a
          class="footer__email"
          :href="tellink[tipo]"
        ><span>{{tel[tipo]}}</span></a>
        <p>Nuestro horario de atención es {{horario[tipo]}}</p>
      </div>
    </div>
    <div class="container">
      <div class="footer__middle row">

        <div class="footer__column col-12 col-md">
          <div class="footer__column-title">Oficinas</div>
          <address class="footer__address">Teófilo Borunda 2400, Jardines del Santuario, 31020 Chihuahua, Chih.</address>
          <a
            class="footer__phone"
            href="tel:+526144111558"
          >+52 614 411 1558</a>
        </div>
        <div class="footer__column col-12 col-sm">
          <div class="footer__column-title">Unidades médicas</div>

          <ul class="footer__column-menu">
            <li class="footer__column-item">
              <a
                class="footer__column-link "
                href="https://goo.gl/maps/Etf2p5a7QzcatS8W6"
                target="_blank"
              >Teófilo Borunda</a>
            </li>
            <li class="footer__column-item">
              <a
                class="footer__column-link "
                href="https://goo.gl/maps/DSWgydfYyPiU3wR36"
                target="_blank"
              >Juan Escutia</a>
            </li>
            <li class="footer__column-item">
              <a
                class="footer__column-link "
                href="https://goo.gl/maps/oudGexS1MbQpZGop8"
                target="_blank"
              >Paseos de Chihuahua</a>
            </li>
          </ul>
        </div>
        <div class="footer__column col-12 col-sm">
          <div class="footer__column-title">Links rápidos</div>

          <ul class="footer__column-menu">
            <li class="footer__column-item">
              <router-link
                class="footer__column-link "
                to="/empresas/login"
                v-text="'Atención a empresas'"
              />
            </li>
            <li class="footer__column-item">
              <router-link
                class="footer__column-link "
                to="/empresas/factura"
                v-text="'Solicita tu factura'"
              />
            </li>
            <li class="footer__column-item">
              <router-link
                class="footer__column-link "
                to="/empresas/ambulancia"
                v-text="'Solicita tu servicio de ambulancia'"
              />
            </li>
            <li class="footer__column-item">
              <router-link
                class="footer__column-link "
                to="/personal"
              >
                <span style="color:#e20338!important"><strong>Personal Cruz Roja</strong></span>
              </router-link>
            </li>
            <li class="footer__column-item">
              <router-link
                class="footer__column-link "
                to="/aviso-de-privacidad"
                v-text="'Aviso de privacidad'"
              />
            </li>
          </ul>
        </div>
        <div class="footer__column footer__column col-12 col-lg">

          <ul class="footer__social social--technical social">
            <li class="social__item">
              <a
                class="social__link"
                href="https://www.facebook.com/cruzrojacuu"
                target="_blank"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 64 64"
                >
                  <path d="M37.2 64H28c-2.5 0-4.5-2-4.5-4.5V37H17c-2.5 0-4.5-2-4.5-4.5v-6.9c0-2.5 2-4.6 4.5-4.6h6.5v-5.5c0-9.1 6.2-15.5 15-15.5H47c1.4 0 2.5 1.1 2.5 2.5v13c0 1.4-1.1 2.5-2.5 2.5h-5.5v3H47c1.4.1 2.7.7 3.5 1.8.8 1.1 1.2 2.4.9 3.8l-1.3 6.7c-.3 2.1-2.1 3.7-4.4 3.7h-4.3v22.5c.1 2.5-1.9 4.5-4.2 4.5zm-8.7-5h8V34.5c0-1.4 1.1-2.5 2.5-2.5h6.3l1.2-6H39c-1.4 0-2.5-1.1-2.5-2.5v-8c0-1.4 1.1-2.5 2.5-2.5h5.5V5h-6c-6.1 0-10 4.1-10 10.5v8c0 1.4-1.1 2.5-2.5 2.5h-8.5v6H26c1.4 0 2.5 1.1 2.5 2.5V59z" />
                </svg>
                <span class="visually-hidden">facebook de cruz roja chihuahua</span>
              </a>
            </li>
            <li class="social__item">
              <a
                class="social__link"
                href="https://www.instagram.com/cruzrojachih/"
                target="_blank"
              >
                <svg
                  width="18"
                  height="18"
                  aria-label="instagram icon"
                  viewBox="0 0 64 64"
                >
                  <path d="M32 16.9c-8.3 0-15 6.8-15 15.1s6.7 15.1 15 15.1S47 40.3 47 32s-6.7-15.1-15-15.1zm0 25.2c-5.5 0-10-4.5-10-10.1s4.5-10.1 10-10.1S42 26.4 42 32s-4.5 10.1-10 10.1zM47 10c-2.8 0-5 2.3-5 5s2.2 5 5 5 5-2.3 5-5-2.2-5-5-5z" />
                  <path d="M48 0H16C7.2 0 0 7.2 0 16v31.7C0 56.7 7.3 64 16.3 64h31.5C56.7 64 64 56.7 64 47.7V16c0-8.8-7.2-16-16-16zm11 47.7C59 53.9 54 59 47.8 59H16.3C10.1 59 5 54 5 47.8V16C5 9.9 9.9 5 16 5h32c6.1 0 11 4.9 11 11v31.7z" />
                </svg>
                <span class="visually-hidden">instagram de cruz roja chihuahua</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="footer__copyright col-12 col-sm-6">
          © 2022 <span>Cruz Roja Mexicana I.A.P. Delegación Chihuahua.</span> Todos los derechos.
        </div>
        <div class="footer__design col-12 col-sm-6">
          <desarrollado />
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import Desarrollado from "@/components/Desarrollado.vue";
export default {
  name: "Footer",
  components: { Desarrollado },
  data() {
    return {
      tipo: "a",
      tel: {
        a: "614 411 1558 ext. 101",
        b: "614 411 9500",
      },
      tellink: {
        a: "tel:+526144111558",
        b: "tel:+526144119500",
      },
      horario: {
        a: "de lunes a viernes de 8am a 4pm horas en días hábiles",
        b: "24/7 todos los días del año",
      },
    };
  },
  methods: {
    _checkRuta() {
      this.tipo =
        this.$route.name === "CitasForm" ||
        this.$route.name === "CitasConfirmacion"
          ? "b"
          : "a";
    },
  },
  watch: {
    $route(a, b) {
      this._checkRuta();
    },
  },
};
</script>